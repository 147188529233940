let serverHost = "https://upduck.ducklabs.com.br";

if (process.env.NODE_ENV === "development") {
  serverHost = "http://localhost:3000";
}

export const serverUrl = (path: string) => {
  return `${serverHost}${path}`;
};

export const authHeaders = () => {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("login_token")}`,
  };
};
