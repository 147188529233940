import React from "react";

type Feature = {
  name: string;
  description: string;
};

function Header() {
  return (
    <header className="py-6 px-4">
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-3xl font-bold text-sunshine-yellow">UpDuck</h1>
        <nav>
          <ul className="flex space-x-8">
            <li>
              <a href="#features" className="hover:text-sunshine-yellow">
                Features
              </a>
            </li>
            <li>
              <a
                href="https://github.com/duck-labs/upduck"
                className="hover:text-sunshine-yellow"
                target="_blank"
                rel="noreferrer"
              >
                Github
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

function CodeBlock() {
  return (
    <div className="w-full max-w-2xl mx-auto bg-slate-gray rounded-lg shadow-lg">
      <div className="bg-gray-800 p-2 rounded-t-lg flex items-center">
        <div className="flex space-x-2">
          <span className="w-3 h-3 bg-red-500 rounded-full"></span>
          <span className="w-3 h-3 bg-yellow-500 rounded-full"></span>
          <span className="w-3 h-3 bg-green-500 rounded-full"></span>
        </div>
        <div className="ml-auto text-gray-400 text-xs">Terminal</div>
      </div>

      <div className="relative bg-black font-mono p-4 rounded-b-lg">
        <div>
          <span className="text-green-500">~$</span>&nbsp;curl
          https://upduck.ducklabs.com.br/installer.sh | bash
        </div>
        <div className="mt-2">
          <span className="text-green-500">~$</span>&nbsp;upduck auth{" "}
          <span className="text-sunshine-yellow">&lt;server-id&gt;</span>{" "}
          <span className="text-sunshine-yellow">&lt;token&gt;</span>
        </div>
        <div className="mt-2">
          <span className="text-green-500">~$</span>&nbsp;upduck monitor
        </div>
      </div>
    </div>
  );
}

function HeroSection() {
  return (
    <section id="hero" className="h-[calc(100vh-84px)] px-2">
      <div className="container mx-auto flex flex-col items-center justify-center h-full">
        <div className="text-center">
          <h2 className="text-5xl font-extrabold mb-4">
            Easily monitor your servers
          </h2>
          <p className="text-xl mb-8">
            Are you running out the cloud? Start tracking your server's uptime
            and performance
          </p>
        </div>
        <div className="mt-8">
          <CodeBlock />
        </div>
        <div className="mt-8 text-center">
          <div className="mt-2 flex justify-center">
            <a
              href="/dash"
              className="block bg-sunshine-yellow text-black py-3 px-6 rounded-full hover:bg-sunshine-yellow transition"
            >
              Create Account
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

function FeatureSection() {
  const features: Feature[] = [
    {
      name: "Key OS Indicators",
      description: "Memory, SWAP, CPU and Disk usage capturing every minute",
    },
    {
      name: "System's Uptime and Temperature",
      description:
        "Keep an eye on system health metrics that is not directly related to performance metrics",
    },
    {
      name: "Alerts",
      description:
        "Receive notifications when an indicator reaches a limit, such as if the disk is at 80%",
    },
  ];

  return (
    <section id="features" className="pt-20 pb-60 border-t-2 border-gray-700">
      <div className="container mx-auto">
        <h2 className="text-4xl text-center font-extrabold">Features</h2>
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 mt-24">
          {features.map((feature) => (
            <div
              className="border rounded-xl px-6 py-8"
              style={{ borderColor: "#484848" }}
            >
              <h3 className="text-lg mb-2">{feature.name}</h3>
              <p className="text-gray-400 mt-2">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

function CTA() {
  return (
    <section className="pt-20 pb-40 border-t-2 border-gray-700">
      <div className="container mx-auto max-w-4xl">
        <h2 className="text-4xl text-center font-extrabold">
          Ready to get started?
        </h2>
        <div className="mx-auto grid grid-cols-1 md:grid-cols-2 gap-16 mt-36">
          <div>
            <h3 className="text-2xl">Self-hosted</h3>
            <div className="mt-4 text-gray-300">
              <p>
                You can deploy UpDuck dashboard into a server of yours (ex: at
                the cloud) to monitor other servers.
              </p>
              <p className="mt-4">
                More instructions on&nbsp;
                <a
                  href="https://github.com/duck-labs/upduck"
                  className="hover:text-sunshine-yellow underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Github
                </a>
                .
              </p>
            </div>
          </div>
          <div>
            <h3 className="text-2xl">Service</h3>
            <div className="mt-4 text-gray-300">
              <p>
                Start for free by creating an account to obtain an auth token
                for upduck's monitor
              </p>
              <div className="bg-sunshine-yellow text-black py-3 px-6 rounded-full hover:bg-sunshine-yellow transition inline-block mt-8">
                <a href="/dash">Create Account</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function Reach() {
  return (
    <section className="pt-20 pb-40">
      <div className="container mx-auto">
        <h2 className="text-2xl text-center font-bold">Talk to us</h2>
        <div className="flex justify-center space-x-8 mt-12">
          <div className="bg-sunshine-yellow text-black py-3 px-6 rounded-full hover:bg-sunshine-yellow transition">
            <a
              href="https://reddit.com/r/upduck"
              target="_blank"
              rel="noreferrer"
            >
              r/upduck
            </a>
          </div>
          <div className="bg-sunshine-yellow text-black py-3 px-6 rounded-full hover:bg-sunshine-yellow transition">
            <a href="mailto:quack@upduck.ducklabs.com.br">mailto:quack@upduck.ducklabs.com.br</a>
          </div>
          <div className="bg-sunshine-yellow text-black py-3 px-6 rounded-full hover:bg-sunshine-yellow transition">
            <a href="https://t.me/upduck" target="_blank" rel="noreferrer">
              telegram:upduck
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

function Home() {
  return (
    <>
      <Header />
      <HeroSection />
      <FeatureSection />
      <CTA />
      <Reach />
    </>
  );
}

export default Home;
