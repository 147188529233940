import { FormEvent } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { serverUrl } from "../config";

const doLogin = (navigate: NavigateFunction) => {
  return (event: FormEvent<HTMLFormElement>) => {
    const form = event.target as HTMLFormElement;
    const emailInput = form.querySelector(
      'input[name="email"]',
    ) as HTMLInputElement;

    (async () => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: emailInput.value }),
      };

      try {
        const result = await fetch(
          serverUrl("/api/v1/users/login"),
          requestOptions,
        );

        if (result.status === 200) {
          const body = await result.json();
          navigate(`/dash/login/${body.id}/confirm`);
        }
      } finally {
      }
    })();

    event.preventDefault();
  };
};

function Login() {
  const navigate = useNavigate();

  return (
    <section className="bg-jet-black text-white">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a href="#" className="flex items-center mb-6 text-2xl font-semibold">
          UpDuck
        </a>
        <div className="w-full rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl text-center">
              Login with your email
            </h1>
            <form
              className="space-y-4 md:space-y-6"
              action="#"
              onSubmit={doLogin(navigate)}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium"
                >
                  E-mail
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="border rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400"
                  placeholder="name@domain.com"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full text-jet-black focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-sunshine-yellow"
              >
                Login
              </button>
              <p className="text-sm font-light text-gray-400">
                If you don't have an account, it will be created on first login
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
