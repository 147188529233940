import { MouseEvent, useContext } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { authHeaders, serverUrl } from "../../config";
import { DashContext } from "../Dash";

type PropsType = {
  step: string;
};

const createNewServerAndKey = (
  navigate: NavigateFunction,
  setPrivateServerToken: Function | null,
) => {
  return (event: MouseEvent<HTMLButtonElement>) => {
    const requestOptions = {
      method: "POST",
      headers: authHeaders(),
    };

    (async () => {
      try {
        const result = await fetch(
          serverUrl("/api/v1/servers"),
          requestOptions,
        );

        if (result.status === 200) {
          const body = await result.json();
          if (setPrivateServerToken) {
            setPrivateServerToken(body.token);
            navigate(`/dash/servers/${body.serverId}/setup`);
          }
        }
      } finally {
      }
    })();
  };
};

export const NewServer = ({ step }: PropsType) => {
  const { setPrivateServerToken, serverPrivateToken } = useContext(DashContext);
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <div>
      <div>
        <span className="block">
          1. When you click "Create Key", a secret will be shown only once and
          you must keep it safely
        </span>
        {step === "create" ? (
          <button
            onClick={createNewServerAndKey(navigate, setPrivateServerToken)}
            className="mt-3 text-jet-black focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-sunshine-yellow cursor-pointer"
          >
            Create Key
          </button>
        ) : null}
      </div>
      <div className="mt-8">
        <span className="block">2. Setup the client on your server</span>
        {step === "setup" ? (
          <div className="mt-3">
            {serverPrivateToken ? (
              <div className="text-gray-300">
                You can see your token{" "}
                <span className="text-sunshine-yellow">(in yellow)</span> only
                once. Store it safely.
              </div>
            ) : (
              <div className="text-gray-300">
                Your key is <span className="text-red-500">not available</span>{" "}
                for display after creation. If you lost it, you must generate a
                new one.
              </div>
            )}
            <div className="mt-2 relative bg-black font-mono p-4 rounded-lg max-w-2xl">
              <div>
                <span className="text-green-500">~$</span>&nbsp;curl
                https://upduck.ducklabs.com.br/installer.sh | bash
              </div>
              <div className="mt-2">
                <span className="text-green-500">~$</span>&nbsp;upduck auth{" "}
                <span className="text-sunshine-yellow">{id}</span>{" "}
                <span
                  className={
                    serverPrivateToken ? "text-sunshine-yellow" : "text-red-500"
                  }
                >
                  {serverPrivateToken || "<<NOT AVAILABLE>>"}
                </span>
              </div>
              <div className="mt-2">
                <span className="text-green-500">~$</span>&nbsp;upduck monitor
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="mt-8">
        <span className="block">
          3. Start watching your metrics and configure alarms
        </span>
        {id ? (
          <button
            onClick={() => navigate(`/dash/servers/${id}`)}
            className="mt-3 text-jet-black focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-sunshine-yellow cursor-pointer"
          >
            Done
          </button>
        ) : null}
      </div>
    </div>
  );
};
