import { FormEvent } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { serverUrl } from "../config";

const doConfirm = (navigate: NavigateFunction, loginId: string | undefined) => {
  return (event: FormEvent<HTMLFormElement>) => {
    const form = event.target as HTMLFormElement;
    const passwordInput = form.querySelector(
      'input[name="password"]',
    ) as HTMLInputElement;

    if (!loginId) {
      event.preventDefault();
      return;
    }

    (async () => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password: passwordInput.value }),
      };

      try {
        const result = await fetch(
          serverUrl(`/api/v1/users/login/${loginId}/confirm`),
          requestOptions,
        );

        if (result.status === 200) {
          const body = await result.json();
          localStorage.setItem("login_token", body.token);
          navigate("/dash");
        }
      } finally {
      }
    })();

    event.preventDefault();
  };
};

function LoginConfirm() {
  const navigate = useNavigate();
  const { loginId } = useParams();

  return (
    <section className="bg-jet-black text-white">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a href="#" className="flex items-center mb-6 text-2xl font-semibold">
          UpDuck
        </a>
        <div className="w-full rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl text-center">
              Enter the one-time-password
            </h1>
            <form
              className="space-y-4 md:space-y-6"
              action="#"
              onSubmit={doConfirm(navigate, loginId)}
            >
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium"
                >
                  Password received by e-mail
                </label>
                <input
                  type="text"
                  name="password"
                  id="password"
                  className="border rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400"
                  placeholder="wsg43ycj"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full text-jet-black focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-sunshine-yellow"
              >
                Confirm
              </button>
              <p className="text-sm font-light text-gray-400">&nbsp;</p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginConfirm;
