import React from "react";
import "./App.css";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import Home from "./Home";
import Login from "./login/Login";
import Dash from "./dash/Dash";
import LoginConfirm from "./login/LoginConfirm";
import { ShowServer } from "./dash/servers/Show";
import { DashHome } from "./dash/Home";
import { NewServer } from "./dash/servers/New";

type ProtectedRoutePropsType = {
  reverse: boolean;
};

const ProtectedRoute = ({ reverse }: ProtectedRoutePropsType) => {
  if (!localStorage.getItem("login_token") && !reverse) {
    return <Navigate to="/dash/login" replace />;
  }

  if (localStorage.getItem("login_token") && reverse) {
    return <Navigate to="/dash" replace />;
  }

  return <Outlet />;
};

function App() {
  return (
    <div className="App bg-jet-black text-white min-h-full">
      <BrowserRouter>
        <div>
          <Routes>
            <Route element={<ProtectedRoute reverse={true} />}>
              <Route
                path="/dash/login/:loginId/confirm"
                element={<LoginConfirm />}
              />
              <Route path="/dash/login" element={<Login />} />
            </Route>
            <Route element={<ProtectedRoute reverse={false} />}>
              <Route path="/dash" element={<Dash />}>
                <Route index element={<Navigate to="servers" />} />
                <Route path="servers" element={<DashHome />} />
                <Route
                  path="servers/new"
                  element={<NewServer step="create" />}
                />
                <Route path="servers/:id" element={<ShowServer />} />
                <Route
                  path="servers/:id/setup"
                  element={<NewServer step="setup" />}
                />
              </Route>
            </Route>
            <Route path="/" element={<Home />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
